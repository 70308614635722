/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalBadgesQuery } from "../../../__generated__/queries";
import Badge from "./Badge";

const AnimalBadges: React.FC<{ activeAnimal?: string }> = ({
  activeAnimal,
}) => {
  const data = useStaticQuery<AnimalBadgesQuery>(graphql`
    query AnimalBadgesQuery {
      allMarkdownRemark(
        filter: { fields: { sourceName: { eq: "animal-pages" } } }
        sort: { fields: [frontmatter___order, frontmatter___name], order: ASC }
      ) {
        nodes {
          id
          frontmatter {
            name
            online
            url
            type
            hook
            badge {
              gradient_start
              gradient_stop
              picture {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const animals = data.allMarkdownRemark.nodes.filter((node) => {
    if (process.env.NODE_ENV === "production") {
      return node.frontmatter?.online === true;
    }
    return true;
  });

  const activeAnimalIndex = animals.findIndex(
    (node) => node.id === activeAnimal
  );
  const targetIndexes: number[] = [];
  for (
    let index = 0;
    index < (activeAnimalIndex === -1 ? animals.length : 3);
    index++
  ) {
    targetIndexes.push((activeAnimalIndex + index + 1) % animals.length);
  }

  return (
    <div
      sx={{
        display: "grid",
        columnGap: 1,
        gridTemplateColumns: [
          "1fr",
          "1fr 1fr",
          "1fr 1fr",
          "1fr 1fr 1fr",
          "1fr 1fr 1fr",
        ],
        marginTop: [0, 6, 6],
        marginBottom: (theme): number[] => [0, theme.space[7] * -1],
      }}
    >
      {targetIndexes.map((index) => {
        const node = animals[index];
        const { name, url, badge, hook } = node.frontmatter || {};
        const { picture, gradient_start, gradient_stop } = badge || {};

        return (
          <Badge
            key={index}
            content={hook}
            gradientStart={gradient_start}
            gradientStop={gradient_stop}
            name={name}
            picture={picture}
            url={url}
          />
        );
      })}
    </div>
  );
};

export default memo(AnimalBadges);
