/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx, Styled } from "theme-ui";

const Badge: React.FC<{
  picture?: {
    childImageSharp?: {
      fluid?: {
        base64?: string | null;
        aspectRatio?: number | null;
        src?: string | null;
        srcSet?: string | null;
        sizes?: string | null;
      } | null;
    } | null;
  } | null;
  name?: string | null;
  content?: string | null;
  url?: string | null;
  gradientStart?: string | null;
  gradientStop?: string | null;
}> = ({ picture, name, content, url, gradientStart, gradientStop }) => {
  const { fluid } = picture?.childImageSharp || {};

  return (
    <Styled.a
      as={Link}
      // @ts-ignore
      to={`/${url}` || "/"}
      sx={{
        alignItems: "center",
        backgroundColor: "muted",
        borderBottomColor: "accent",
        borderBottomStyle: "solid",
        borderBottomWidth: [0, ".25rem"],
        display: ["flex", "block"],
        marginBottom: [3, 7],
        padding: [4, 3, 4],
        paddingBottom: [4, 5],
        position: "relative",
        textDecoration: "none",
        ":hover": {
          textDecoration: "none",
          color: "inherit",
        },
        "&:last-of-type": {
          marginBottom: [0, 7],
        },
      }}
    >
      {fluid && (
        <div
          sx={{
            backgroundImage: `radial-gradient(${gradientStart}, ${gradientStop})`,
            borderRadius: "100%",
            height: ["100px", 0],
            marginBottom: [0, 4],
            marginTop: [0, "-4rem"],
            marginRight: [4, 0],
            position: "relative",
            paddingTop: [0, "100%"],
            minWidth: ["100px", 0],
          }}
        >
          <div
            sx={{
              height: "100%",
              left: 0,
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <Img
              alt={name || ""}
              fluid={fluid as FluidObject}
              style={{ height: "100%", width: "100%", userSelect: "none" }}
              imgStyle={{ objectFit: "contain" }}
              draggable={false}
            />
          </div>
        </div>
      )}
      {name && (
        <div
          sx={{
            color: "accent",
            fontSize: ["18px", "22px", "22px", "22px", "subheading"],
            marginBottom: 3,
            textAlign: ["left", "center"],
          }}
        >
          {name}
        </div>
      )}
      {content && (
        <div sx={{ display: ["none", "block"] }}>
          <Styled.p sx={{ textAlign: "center" }}>{content}</Styled.p>
        </div>
      )}
    </Styled.a>
  );
};

export default memo(Badge);
